import { useEffect, useState } from "react";
import InputMask from "react-input-mask";
import "./style.css";
import { useNavigate } from "react-router-dom";
import { routes } from "../../routes";
export default function FormL({ closePop }) {
  const Navigate = useNavigate();
  const [tel, setTel] = useState("");
  const [name, setName] = useState("");
  const [loading, isLoading] = useState(false);
  const [error, setError] = useState(false);
  function sendMessage() {
    let form = new FormData();
    form.append("tel", tel);
    form.append("name", name);
    fetch("https://brandside.pro/amocrm/webhook.php", {
      method: "POST",
      body: JSON.stringify({
        phone: tel,
        name: name,
        url_main: "https://брендсайд.рф",
      }),
    }).then((response) => {
      if (response.status == 200) {
        fetch("https://брендсайд.рф/bot.php", {
          method: "post",
          body: form,
        }).then((response) => {
          if (response.status == 200) {
            window.location.href = "https://брендсайд.рф/thanks";
          }
        });
      }
    });
  }
  function CloseMe(e) {
    if (e.target.id == "popBody") {
      closePop(false);
    }
  }
  useEffect(() => {
    if (tel.length > 5) {
      setError(false);
    }
  }, [tel]);
  return (
    <div className="popForm" onClick={CloseMe} id="popBody">
      <div className="popFormBody">
        <p className="formZ">
          <span>Заполните</span> <br />
          форму
        </p>
        <p className="svz">Мы свяжемся с вами через 1 минуту</p>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Ваше имя"
        />
        <InputMask
          mask="+7 999 999 99 99"
          maskChar={null}
          value={tel}
          className="forSale"
          onChange={(e) => setTel(e.target.value)}
          placeholder="Телефон*"
          style={error ? { border: "2px solid red" } : {}}
        />

        <div
          className="sendFrom"
          style={
            tel.length != 16 || loading
              ? { pointerEvents: "none", opacity: "0.6" }
              : {}
          }
          onClick={() => {
            sendMessage();
            isLoading(true);
          }}
        >
          {loading == true ? "Пожалуйста подождите" : "отправить"}{" "}
          <span class="flare"></span>
        </div>
      </div>
    </div>
  );
}
