import "./App.css";
import { Routes, Route } from "react-router-dom";
import { routes } from "./routes";
import Landing from "./Pages/Landing";
import Thanks from "./Pages/Thanks";
import Posad from "./Pages/Posad";
import "./fonts/stylesheet.css";
function App() {
  return (
    <div className="App">
      <Routes>
        <Route path={routes.landing} element={<Landing></Landing>}></Route>
        <Route path={routes.thanks} element={<Thanks />}></Route>
        <Route path={routes.posad} element={<Posad />}></Route>
      </Routes>
    </div>
  );
}

export default App;
