import "./style.css";
import firstI from "./img/first.svg";
import big from "./img/circle.png";
import logo from "./img/bigLogo.png";
import ic1 from "./img/ic1.svg";
import ic2 from "./img/ic2.svg";
import mob from "./img/mobCircle.png";
import ic3 from "./img/ic3.svg";
import Flip from "react-reveal/Flip";
import { Zoom } from "react-reveal";
export default function Work({ isForm }) {
  return (
    <div className="work">
      <div className="workBody">
        <img src={firstI} alt="" className="firstI" />
        <Flip left>
          <h3>
            Работаем <span>для вас</span>
          </h3>
        </Flip>
        <img
          src={document.body.clientWidth > 640 ? big : mob}
          alt=""
          className="car"
        />
        <img src={logo} alt="" className="bigLogo" />
        <p className="other">И многие другие</p>
        <div className="hours">
          <p>Уже через 3 часа закрепим за вами право на знак. </p>
          <span>Работайте спокойно, пока идет регистрация!</span>
        </div>
        <div className="garant">
          <div className="garantEm gr1">
            <img src={ic1} alt="" />
            <p>8 часов</p>
            <span>скорость подачи заявки в Роспатент</span>
          </div>
          <div className="garantEm gr2">
            <img src={ic2} alt="" />
            <p>От нас 100%</p>
            <span>вовлечённость в процесс</span>
          </div>
          <div className="garantEm gr3">
            <img src={ic3} alt="" />
            <p>30% скидка</p>
            <span>на госпошлины</span>
          </div>
        </div>
        <Zoom top>
          <div className="button" onClick={() => isForm(true)}>
            УЗНАТЬ О РЕГИСТРАЦИИ <span class="flare"></span>
          </div>
          <span className="minute">Перезвоним через 1 минуту</span>
        </Zoom>
      </div>
    </div>
  );
}
