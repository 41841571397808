import "./style.css";
import phone from "./img/phone.png";
import girl from "./img/girl.png";
import Zoom from "react-reveal/Zoom";
export default function Smart({ isForm }) {
  return (
    <div className="smart">
      <div className="smartBody">
        <Zoom top>
          <h3>
            SMART
            <br /> регистрация
          </h3>
        </Zoom>
        <h2>товарных знаков - это реально!</h2>
        <Zoom>
          <p>
            Подписывайтесь на нашего telegram бота <br /> и получите
          </p>
        </Zoom>
        <div className="smartContent">
          <div className="smartLeft">
            <ul>
              <li>
                <div>1</div>
                <p>Бесплатный анализ тождественности</p>
              </li>
              <li>
                <div>2</div>
                <p>30% скидку на госпошлины</p>
              </li>
              <li>
                <div>3</div>
                <p>Бонусные консультации</p>
              </li>
              <li>
                <div>4</div>
                <p>Рассрочку 0% на 2 месяца</p>
              </li>
              <li>
                <div>5</div>
                <p>Оперативную поддержку</p>
              </li>
              <li>
                <div>6</div>
                <p>Изменения в законодательстве</p>
              </li>
              <li>
                <div>7</div>
                <p>Полезные советы</p>
              </li>
            </ul>
            {document.body.clientWidth > 640 && (
              <div>
                <a
                  style={{ display: "block", textDecoration: "none" }}
                  className="button"
                  href="https://t.me/brandsideRF_bot"
                  target="_blank"
                >
                  ПОДПИСАТЬСЯ
                </a>
                <span className="obesh">Обещаем - никакого спама</span>
              </div>
            )}
          </div>
          <div className="smartRight">
            <img src={girl} alt="" className="girl" />
            <img src={phone} alt="" className="phone" />
          </div>
          {document.body.clientWidth < 640 && (
            <div>
              <a
                className="button"
                href="https://t.me/brandsideRF_bot"
                target="_blank"
                style={{ display: "block", textDecoration: "none" }}
              >
                ПОДПИСАТЬСЯ
              </a>
              <span className="obesh">Обещаем - никакого спама</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
