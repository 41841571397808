import "./style.css";
import img1 from "./img/1.png";
import img2 from "./img/2.png";
import img3 from "./img/3.png";
import img4 from "./img/4.png";
import ic1 from "./img/ic1.svg";
import ic2 from "./img/ic2.svg";
import ic3 from "./img/ic3.svg";
import ic4 from "./img/ic4.svg";
import ic5 from "./img/ic5.svg";
import ic6 from "./img/ic6.svg";
import ic7 from "./img/ic7.svg";
import ic8 from "./img/ic8.svg";
import ic9 from "./img/ic9.svg";
import ic10 from "./img/ic10.svg";
import ic11 from "./img/ic11.svg";
import line from "./img/line.svg";
import Rotate from "react-reveal/Rotate";

export default function Steps({ isForm }) {
  return (
    <div className="steps">
      <div className="stepsBody">
        <h3>4 простых шага</h3>
        <p>Все ходы уже спланированы</p>
        <div className="tline"></div>
        <div className="stepBlock">
          <Rotate bottom right>
            <img src={img1} alt="" />
          </Rotate>
          <div className="stepContent">
            <p className="stepTitle">Шаг Первый</p>
            <ul>
              <li>
                <img src={ic1} alt="" />
                <p>Получаем информацию о товарном знаке и продуктах/услугах</p>
              </li>
              <img src={line} alt="" />
              <li>
                <img src={ic2} alt="" />
                <p>
                  Проверяем на тождество по зарегистрированным товарными знаками
                </p>
              </li>{" "}
              <img src={line} alt="" />
              <li>
                <img src={ic3} alt="" />
                <p>Полный поиск</p>
              </li>
            </ul>
            <p className="prov">Бесплатная экспресс-проверка за 90 минут</p>
          </div>
        </div>
        <div className="stepBlock">
          <Rotate bottom left>
            <img src={img2} alt="" />{" "}
          </Rotate>
          <div className="stepContent">
            <p className="stepTitle">Шаг Второй</p>
            <div className="grs">
              <p>Подача заявки и закрепление приоритета</p>
              <span>От 8 часов с момента согласования</span>
            </div>
            <ul>
              <li>
                <img src={ic4} alt="" />
                <p>
                  Подбираем классы МКТУ в соответствии со стратегией вашего
                  бизнеса
                </p>
              </li>{" "}
              <img src={line} alt="" />
              <li>
                <img src={ic5} alt="" />
                <p>Подаём заявку в Роспатент</p>
              </li>{" "}
              <img src={line} alt="" />
              <li>
                <img src={ic6} alt="" />
                <p>
                  Отправляем уведомление о приёмке и регистрации заявки (форма
                  940)
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div className="poshlina">
          {" "}
          Вы оплачиваете госпошлины со скидкой 30%
        </div>
        <div className="stepBlock stt">
          <Rotate bottom left>
            <img src={img3} alt="" />
          </Rotate>
          <div className="stepContent">
            <p className="stepTitle">Шаг Третий</p>
            <p className="grs">
              Экспертиза в Роспатенте
              <span>от 3.5 мес (в среднем 6)</span>
            </p>
            <ul>
              <li>
                <img src={ic7} alt="" />
                <p>Мы ведем делопроизводство</p>
              </li>{" "}
              <img src={line} alt="" />
              <li>
                <img src={ic8} alt="" />
                <p>Отвечаем на запросы Роспатента</p>
              </li>{" "}
              <img src={line} alt="" />
              <li>
                <img src={ic9} alt="" />
                <p>Своевременно информируем Вас о ходе регистрации</p>
              </li>
            </ul>
          </div>
        </div>
        <div className="stepBlock stt">
          <Rotate bottom right>
            <img src={img4} alt="" />
          </Rotate>
          <div className="stepContent">
            <p className="stepTitle">Шаг Четвертый</p>
            <p className="grs">
              Успешная регистрация и выдача свидетельства
              <span>от 10 дней</span>
            </p>
            <ul>
              <li>
                <img src={ic10} alt="" />
                <p>
                  Вы оплачиваете госпошлину за выдачу свидетельства со скидкой
                  30%
                </p>
              </li>{" "}
              <img src={line} alt="" />
              <li>
                <img src={ic11} alt="" />
                <p>Получаете свидетельство о регистрации товарного знака</p>
              </li>
            </ul>
            <div className="button" onClick={() => isForm(true)}>
              Подать заявку <span class="flare"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
