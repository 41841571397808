import "./style.css";
import logo from "./img/logo.png";
import tg from "./img/tg.svg";
import wt from "./img/wt.svg";
import lamp from "./img/lamp.png";
import arrow from "./img/arrow.svg";
import gal from "./img/gal.svg";
import ach from "./img/test.svg";
import man from "./img/man.png";
import Zoom from "react-reveal/Zoom";
import mobMan from "./img/mobMan.png";
import Bounce from "react-reveal/Bounce";
export default function Header({ isForm }) {
  return (
    <div className="header">
      <div className="headerTop">
        <img src={logo} alt="" className="logo" />
        <p className="dostavka">
          <b>Бесплатная</b> Экспресс- <br />
          проверка товарного знака
        </p>
        <div className="button button_header" onClick={() => isForm(true)}>
          Заказать <span class="flare"></span>
        </div>
        <p className="askMe">Задайте ваш вопрос</p>
        <a
          href="https://t.me/brandsideRF_bot"
          target="_blank"
          style={{ display: "flex", alignItems: "center" }}
        >
          <img src={tg} alt="" className="icon" />
        </a>

        <a href="tel:8 (800) 222-04-03" className="MARIA">
          8 (800) 222-04-03
        </a>
      </div>
      <div className="headerCont">
        <div className="headerLeftContent">
          <Zoom right>
            <img src={lamp} alt="" className="lamp" />{" "}
          </Zoom>
          <Zoom top>
            <h1>
              SMART <br />
              <span>РЕГИСТРАЦИЯ</span>
            </h1>
          </Zoom>
          <h2>ТОВАРНОГО ЗНАКА</h2>
          <div className="start">
            от <span>11 500₽</span>
          </div>
          <img src={arrow} alt="" className="arrow" />
          <p>
            На 3 месяца <span className="firstGr">быстрее</span> , на 30%{" "}
            <span className="SecondGr">дешевле</span>{" "}
          </p>
          {document.body.clientWidth > 640 && (
            <div className="buttonStructure">
              <div className="buttonAndText">
                <div className="button buttonMain" onClick={() => isForm(true)}>
                  Бесплатная консультация <span class="flare"></span>
                </div>
                <p>Перезвоним через 1 минуту</p>
              </div>
              <div className="buttonContent">
                <span> от Самозанятых</span>
                <p>
                  до Корпораций <img src={gal} alt="" />
                </p>
              </div>
            </div>
          )}
        </div>
        <Bounce right delay={200}>
          <div className="rightCont">
            <img
              src={document.body.clientWidth > 640 ? man : mobMan}
              className="man"
              alt=""
            />
            {document.body.clientWidth > 640 && (
              <img src={ach} className="ach" alt="" />
            )}
          </div>
        </Bounce>
        {document.body.clientWidth < 640 && (
          <div className="buttonStructure">
            <div className="buttonAndText">
              <div className="button buttonMain" onClick={() => isForm(true)}>
                Бесплатная консультация <span class="flare"></span>
              </div>
              <p>Перезвоним через 1 минуту</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
