import { useState } from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import MarketPlace from "../../Components/MarketPlace";
import Smart from "../../Components/Smart";
import Steps from "../../Components/Steps";
import Work from "../../Components/Work";
import FormL from "../../Components/Form";
import fr from "./img/first.png";
import th from "./img/third.png";
export default function Landing() {
  const [form, isForm] = useState(false);
  return (
    <div className="landing">
      {form && <FormL closePop={isForm}></FormL>}
      <Header isForm={isForm}></Header>
      <MarketPlace isForm={isForm}></MarketPlace>
      <img src={fr} alt="" style={{ display: "block", width: "100%" }} />
      <Work isForm={isForm}></Work>
      <Steps isForm={isForm}></Steps>
      <img src={th} alt="" style={{ display: "block", width: "100%" }} />
      <Smart isForm={isForm}></Smart>

      <Footer isForm={isForm}></Footer>
    </div>
  );
}
